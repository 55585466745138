
.vertical-label{
color: #291571;
font-size: 20px;
font-weight: 500;
}
  .verticalspart {
    display: flex;
    justify-content: center; 
    align-items: center;      
}
  
  .businessverticalscard {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 4px 0px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;

    border-radius: 8px;
    background-color: #fff;  
  }
  .erpheading1{
font-size: 32px;
color: #291571;
font-weight: 600;
  }

  




  


.crmsolutions{
  color: #291571 !important;
}
  
.procard{
  color: #291571;
}
.swipercrm{
  background-color: #cee6ff !important;
  color: #291571 !important;
}
.titlecrm{
  font-weight: 600;
}
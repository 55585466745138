.right-card {
    margin-top: 100px !important;  
}

.headblog{
    color: #291571;
}


.lnkfnt {
    text-decoration: none;
    color: black;color: #291571;
    padding: 10px;
    transition: color 0.3s;
  }
  
.lnkfnt.active {
    color: red;
    font-weight: 600; 
  }
  
.lnkfnt:hover {
    color: red; 
  }

@media (max-width:768px){
    .right-card {
        margin-top: 0px !important;  
    }    
}

.cm1{
    width: 100%;  
    height: auto; 
    object-fit: cover; 
    border-radius: 0.25rem; 
}
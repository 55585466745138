.kgisllogo{
    max-height: 100px;
    max-width: 200px;
}
.footerbg{
    background-color: #291571;
    color: white;
}
.contactmain{
    font-size: 24px;
    font-weight: 700 !important;
  }
.qklink::after{
    content: " ";
    width: 10%;
    height: 2px;
    color: red;
}

.custom-dropdownfoot {
    background: transparent;
    color: white;
    font-size: 1rem;
    border: none;
    cursor: pointer !important; 
  }
  
  .custom-dropdownfoot option {
    background-color: #291571 !important; /* Make the dropdown options transparent */
    color: white;
    cursor: pointer !important; /* Set text color to white */
  }
  
  .custom-dropdownfoot option:hover {
    background-color: #fff !important; 
    color: #291571;
    cursor: pointer !important;
  }
  

  .custom-dropdownfoot:focus {
    outline: none !important; /* Remove focus outline for a cleaner look */
    box-shadow: none !important;
    border: 0 !important;
    cursor: pointer !important;
  }
  






  
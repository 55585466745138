.featurespart .card{
    border: 1px solid #5BFAFF;
    border-radius: 30px;
    box-shadow: 2px 4px 15px 2px rgba(238, 237, 237, 0.8);
    margin: 5px;
    height: 200px;
    color: #291571;
}
.featurespart h4{
  font-size: 20px !important;
  padding: 10px 10px;
}

.billscard{
    background-color: #F4FEFF !important;
}
.helpcard{
    background-color: #FAFFF4 !important;
}
.invoicecard{
    background-color: #FFF4FE !important;
}
.hrcard{
    background-color: #FFF7F4!important;
}
.multistorecard{
  background-color: #F4F4FF !important;
}

.projectcard{
  background-color: #F4FDFF !important;
}
.poscard{
  background-color: #FFFFF4 !important;
}


@media (min-height:426px) and (max-height:769px){
  .featurespart .card{
    height: 250px !important;
    font-size: 20px;
} 
}






  
.kgisllogo{
    max-height: 100px;
    max-width: 200px;
}
.footerbg{
    background-color: #291571;
    color: white;
}
.contactmain{
    font-size: 24px;
    font-weight: 700 !important;
  }
.nav-link{
   cursor: pointer;
}


  
.abappart{
    text-align: center;
}
.abap{
    color: #291571;
    font-family: 'Proxima Nova', sans-serif;
}

.paravalued{
    font-size: 20px;
}

.abapbox{
border: 1px solid  rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    min-height: 420px;

}

.crdhead{
    font-weight: 600;
}
.valuehead{
font-weight: 700;
font-size: 46px;
}

.paravalue{
    font-size: 20px;
}
@media (max-width:426px){
    .paravalue{
        text-align: justify;
     }
}

@media  (min-width:768px) {
    .abap{
        padding: 0px 100px !important;
    }
}


.htext{
    color: #291571;
}
.expcard{
   
    color: #291571 !important;
    background-color: white;
    box-shadow: 1px 1px 10px 2px #afa0e4; 
}
.expcard p,.inovcard p{
  font-weight: 700;
  font-size: 20px;
}
.inovcard{
    background-color: #291571 !important;
    color: white !important;
}
.inovcard,.expcard{
    width: 100%;
    max-width: 200px; /* Limit the maximum width of the card */
    height: 180px; /* Ensure the card is square */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px auto; 
    /* padding: 10px;  */
    transition: transform 0.3s; 
  }
  
  .inovcard:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  .expcard:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  
  .iconimg {
    width: 60px; /* Adjust the size of the icon */
    height: 60px;
    margin-bottom: 10px; /* Space between the icon and text */
  }
  
  /* Ensure responsiveness of the card */
  @media (max-width: 576px) {
    .inovcard {
      max-width: 200px; /* Adjust size for smaller screens */
      height: 200px;
      border: 0 !important;
    }
    .expcard {
      max-width: 200px; /* Adjust size for smaller screens */
      height: 200px;
      border: 0 !important;
    }
  }

  @media (min-width: 768px) {
    .inovcard {
      max-width: 300px; /* Adjust size for smaller screens */
      height: 300px;
      border: 0 !important;
    }
    .expcard {
      max-width: 300px; /* Adjust size for smaller screens */
      height: 300px;
      border: 0 !important;
    }
  }


  
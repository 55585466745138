.whygl{
    background-image: linear-gradient(to top,#fff, #b3cbf4);
    position: relative;
    overflow: hidden;
}
.paragl{
    color: #291571;
    font-size: 18px;
}

.abhead {
    font-size: 72px;
    font-weight: 700;
    background-image: linear-gradient(#291571, #4E28D7); 
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent; 
}

@media (max-width:768px){
    .abhead {
        font-size: 54px;
        background-image: linear-gradient(#291571, #4E28D7); 
        -webkit-background-clip: text; 
        -webkit-text-fill-color: transparent;
        
    } 
    .paragl{
        text-align: justify;
        padding: 0px 10px; 
    }  
}


  .circle {
    position: absolute;
    bottom: -100px; 
    border-radius: 50%;
    background-image: radial-gradient(#291571, #4E28D7);
    animation: moveUp 15s linear infinite;   
  }

  .circle:nth-child(1) {
    width: 20px;
    height: 20px;
    left: 10%;
    animation-duration: 18s;
    animation-delay: 0s;
  }
  
  .circle:nth-child(2) {
    width: 10px;
    height: 10px;
    left: 25%;
    animation-duration: 10s;
    animation-delay: 2s;
  }
  
  .circle:nth-child(3) {
    width: 10px;
    height: 10px;
    left: 40%;
    animation-duration: 14s;
    animation-delay: 2s;
  }
  
  .circle:nth-child(4) {
    width: 10px;
    height: 10px;
    left: 60%;
    animation-duration: 16s;
    animation-delay: 1s;
  }
  
  .circle:nth-child(5) {
    width: 15px;
    height: 15px;
    left: 75%;
    animation-duration: 18s;
    animation-delay: 2s;
  }

  .circle:nth-child(6) {
    width: 10px;
    height: 10px;
    left: 60%;
    animation-duration: 6s;
   
  }


  .circle:nth-child(7) {
    width: 10px;
    height: 10px;
    left: 60%;
    animation-duration: 6s;
   
  }
  
  /* Animation to move the circles from bottom to top */
  @keyframes moveUp {
    0% {
      transform: translateY(100vh); /* Start below the viewport */
    }
    100% {
      transform: translateY(-100vh); /* Move above the viewport */
    }
  }
  
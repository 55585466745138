.yeartext{
    color: #291571 !important;
    border: 1px solid #F3F3F3 !important;
    box-shadow: 1px 1px 2px 3px  #F8F8F8;
    font-size: 64px !important;
}

.profcard{
    background-color: #4E28D7 !important;
    color: #fff !important;
    box-shadow: 1px 1px 2px 3px  #F8F8F8;
    background-color: #F8F8F8;
}

.yeartext1{
    color: #4E28D7 !important;
    font-size: 48px;
    background-color: #F8F8F8;
    border: 1px solid #F3F3F3 !important;
    box-shadow: 1px 1px 2px 3px  #F8F8F8;
}

.profcard:hover{
   transform: scale(1.1,1.1);
}

.yeartext1:hover{
    transform: scale(1.1,1.1);  
}

.cred {
    position: relative;
    width: 300px;
    height: 200px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    overflow: hidden; /* Ensure the circle is contained within the card */
    transition: background-color 0.3s ease;
}
  
.cred:hover {
    background-color: #fff !important;
}
  
.cred::before {
    content: '';
    position: absolute;
    top: -50px; /* Position circle off-screen initially */
    right: -50px; /* Position circle off-screen initially */
    width: 50px;
    height: 50px;
    background-color: #291571;
    background-image: radial-gradient(blue 50%,white 10%); /* Circle color */
    border-radius: 50%;
    transition: all 0.3s ease;
    transform: scale(0); /* Initially scale down */
}
  
.cred:hover::before {
    top: 0;
    right: 0;
    transform: scale(1.1); /* Scale to full size */
}

.cred::after {
    content: '';
    position: absolute;
    top: 60px; /* Position circle off-screen initially */
    right: 60px; /* Position circle off-screen initially */
    width: 50px;
    height: 50px;
    background-color: #007bff; /* Circle color */
    border-radius: 50%;
    transition: all 0.3s ease;
    transform: scale(0); /* Initially scale down */
}

.cred:hover::after {
    top: 0;
    right: 0;
    transform: scale(1.1); /* Scale to full size */
}
  



.cred1 {
  position: relative;
  width: 300px;
  height: 200px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  overflow: hidden; /* Ensure the circle is contained within the card */
  transition: background-color 0.3s ease;
}



.cred1::before {
  content: '';
  position: absolute;
  top: -50px; /* Position circle off-screen initially */
  right: -50px; /* Position circle off-screen initially */
  width: 50px;
  height: 50px;
  background-color: #291571;
  background-image: radial-gradient(blue 50%,white 10%) !important;
  border-radius: 50%;
  transition: all 0.3s ease;
  transform: scale(0); /* Initially scale down */
}

.cred1:hover::before {
  top: 0;
  right: 0;
  transform: scale(1.1); /* Scale to full size */
}

.cred1::after {
  content: '';
  position: absolute;
  top: 60px; /* Position circle off-screen initially */
  right: 60px; /* Position circle off-screen initially */
  width: 50px;
  height: 50px;
  background-color: #fff !important; /* Circle color */
  border-radius: 50%;
  transition: all 0.3s ease;
  transform: scale(0); /* Initially scale down */
}

.cred1:hover::after {
  top: 0;
  right: 0;
  transform: scale(1.1); 
}


.profcard::after {
    content: '';
    position: absolute;
    top: 50px; 
    right: 50px; 
    width: 50px;
    height: 50px;
    background-color: #fff; 
    border-radius: 50%;
    transition: all 0.3s ease;
    transform: scale(0); 
}


.cred1 {
  position: relative;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  overflow: hidden; /* Ensure the circle is contained within the card */
  transition: background-color 0.3s ease;
}

.cred:hover {
  background-color: #e9ecef;
}

.cred1::before {
  content: '';
  position: absolute;
  top: -50px; /* Position circle off-screen initially */
  right: -50px; /* Position circle off-screen initially */
  width: 50px;
  height: 50px;
  background-color: #007bff;
  background-image: radial-gradient(blue 50%,white 10%); /* Circle color */
  border-radius: 50%;
  /* transition: all 0.3s ease; */
  transform: scale(0); /* Initially scale down */
}

.cred1:hover::before {
  top: 0;
  right: 0;
  transform: scale(1.1); /* Scale to full size */
}

.cred1::after {
  content: '';
  position: absolute;
  top: 60px; /* Position circle off-screen initially */
  right: 60px; /* Position circle off-screen initially */
  width: 50px;
  height: 50px;
  background-color: #007bff; /* Circle color */
  border-radius: 50%;
  transition: all 0.3s ease;
  transform: scale(0); /* Initially scale down */
}

.cred1:hover::after {
  top: 0;
  right: 0;
  transform: scale(1.1); /* Scale to full size */
}

.profcard::after {
  content: '';
  position: absolute;
  top: 50px; /* Position circle off-screen initially */
  right: 50px; /* Position circle off-screen initially */
  width: 50px;
  height: 50px;
  background-color: #fff; 
  border-radius: 50%;
  transition: all 0.3s ease;
  transform: scale(0); 
}

.txtrte {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  transition-duration: 1s infinite;
  transition: width 0.8s cubic-bezier(0.86, 0, 0.07, 1), transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}

.rotated {
  width: 300px; /* Adjust as needed */
  transform: rotate(360deg); /* Rotate 360 degrees */
}
@media (min-width:426px) and(max-width:769px){
  .tabscreen{
    padding:0px  500px !important;
  }
}



.implementation{
    font-family: 'Proxima Nova', sans-serif;
}

.headimp{
    background-color: #3D7CC2;
    color: #fff ;
    font-size: 28px;
    border-radius: 25px;
    font-weight: 600;
    border: 0px;
    box-shadow: 1px 1px 5px 1px #cbcaca !important;   
}

.publiccloud{
    box-shadow: 2px 2px 2px 1px rgb(206, 206, 206);
    border: 1px solid rgb(230, 228, 228) !important;
    border-radius: 20px;  
}
.hanahead{
    color: #291571;
    font-weight: 600; 
}
.hanatext{
    color: #291790;
    font-size: 20px;
}

@media (min-width:769px){
    .implementationpart{
       padding: 0px 100px !important;
    } 
    .hanahead{
        font-size: 40px;
    }
}
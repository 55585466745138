.bannerdmbg{
background-image: url("../../Asset/bannerdm.png");
background-size: cover;
height: 100vh;
}
.bannerdmbgmb{
    background-image: url("../../Asset/mbdm.png");
    background-size: 100% 100%;
    height: 50vh;
}

.dmaboutpart{
    color: #291571;
    text-align: start;
}

.bannerdmhead{
    font-size: 60px;
    font-weight: 600;
    text-shadow: 
    1px 1px 2px rgba(0, 0, 0, 0.5), /* Top-left shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.5), /* Bottom-right shadow */
    inset 1px 1px 2px rgba(0, 0, 0, 0.6); /* Simulated inset shadow */
}
.bannerparadm{
    font-size: 20px;
    text-shadow: 1px 1px 3px  black;
    font-weight: 300;
}
.bannerparadm1{
    font-size: 25px;
    text-shadow: 1px 1px 3px  black;
}
.connectbtn{
    font-family: 'Proxima Nova', sans-serif !important;
    color: #291571;
    font-weight: 700;
    font-size: 20px;
}

@media (max-width:768px){
    .bannerdmhead{
        font-size: 24px;
        font-weight: 800;
        text-shadow: 
        1px 1px 2px rgba(0, 0, 0, 0.5), /* Top-left shadow */
        -1px -1px 2px rgba(0, 0, 0, 0.5), /* Bottom-right shadow */
        inset 1px 1px 2px rgba(0, 0, 0, 0.6); /* Simulated inset shadow */
    }
    .bannerparadm{
        font-size: 16px;
        text-shadow: 1px 1px 3px  black;
    } 
    .connectbtn{
        font-family: 'Proxima Nova', sans-serif !important;
        color: #291571;
        font-weight: 600;
        font-size: 16px;
    }
    .bannerparadm1{
        font-size: 16px;
        text-shadow: 1px 1px 3px  black;
        font-weight: 600;
    }
    .dmaboutpart{
        text-align: justify;
    }
}


.sapbannerpart{
    background-image: url("../Asset/Frame\ 31.png");
    background-size: cover;
    color: #fff;
    background-repeat: no-repeat;
}

.sub-links {
    padding: 10px 0;
}
.sub-links a {
    color: #fff; 
    text-decoration: none;
    font-size: 20px;
    padding: 10px 20px;
}
  
  .sub-links a:hover {
    text-decoration: underline;
}

.linkhover{
    text-decoration: none;
} 
.linkhover:hover{
    color: #FBF100;
} 
.sapbannertext h1{
    font-size: 62px;
    font-family: 'Proxima Nova', sans-serif;  
    font-weight: 600 !important;
}

@media (max-width:426px){
    .sapbannertext h1{
        font-size: 30px;
    }
    .fnt{
        font-size: 18px;
        padding: 10px 0px;
        font-weight: 0px;
    }
    .linkhover{
        font-size: 20px;
    }
    .sub-links a{
        font-size: 16px;
    }
    .headingIT{
        text-align: center;
}
.welcome-message{
    text-align: center;
}
    
}

.fnt{
    font-size: 30px;
    font-weight: 300;
}


.active-link {
    color: #ffff00 !important;
    font-weight: 400;
}
  
.linkhover {
    color: #fff;
    text-decoration: none;
}
  
.linkhover:hover {
    color: #ffff00; 
}
.headingIT{
    color: #291571;
    font-size: 40px;
    font-weight: 600;
    
}
.content-below-banner{
    color: #291571; 
}
.itparagraph{
    font-size: 20px;
}

.sub-links:hover{
    color: yellow;
}



























/* .col-lg-1-7 {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; 
    height: 100%;
}
  
.imgfeat {
    width: 80px;
    height: auto;
    margin-bottom: 10px; 
}
  
.erpheading {
    font-size: 24px;
    font-weight: bold;
}
  
@media (max-width: 768px) {
    .col-lg-1-7 {
      flex: 0 0 50%;
      max-width: 50%;
    }
}
  
.custom-col {
  flex: 0 0 calc(100% / 7);
  max-width: calc(100% / 7);
} */

/* @media (max-width: 992px) {
  .custom-col {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
}

@media (max-width: 576px) {
  .custom-col {
    flex: 0 0 calc(100% / 2);
    max-width: calc(100% / 2);
  }
} */



















.container-fluid {
  margin: 0;
  padding: 0;
}

.erpheading1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.rw {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important; /* 7 items in a row */
  gap: 15px; /* Spacing between items */
  justify-content: center !important;
}

.custom-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.imgfeat {
  width: 80px; /* Adjust image size as needed */
  height: 80px;
  object-fit: contain; /* Ensure proper image scaling */
  margin-bottom: 10px; /* Add space below the image */
}

.custom-col p {
  min-height: 40px; /* Ensure consistent height for text */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px; /* Add padding for better text spacing */
  font-size: 14px;
  line-height: 1.2;
  word-wrap: break-word; /* Handle long words */
}


@media (max-width: 768px) {
  .rw {
    grid-template-columns: repeat(2, 1fr) !important; /* 2 items per row on small screens */
  }
}

@media (max-width: 992px) {
  .rw {
    grid-template-columns: repeat(4, 1fr) !important; /* 4 items per row on medium screens */
  }
}




.startpara{
    font-size: 50px;  
}
.fc1{
    border: 1px solid #291571 !important;
    background-color: transparent !important;
}

.privacytext{
    font-size: 14px;
    color: #291571;
}
.hovericon{
    font-size: 30px;
    color: #ED1C24;
}
.smlink{
    font-size: 16px;
}

.endpara{
font-size: 58px;
font-weight: 600;
}

.conversationpara{
    font-size: 24px;
}
.form-control1{
    border: 1px solid #291571 !important;
    color: #291571 !important;
}
.fr{
  border: 0px 0px 0px 1px solid #291571 !important;
}

.contacttext{
    color: #291571 !important;   
}
.contactpara{
    font-size: 18px;
    padding: 0px 0px;
}
.contactheading{
    font-size: 48px;
    font-weight: 600 !important;
    font-family: 'Proxima Nova', sans-serif;  
}

.contactbtn{
    background-image: linear-gradient(to left,#4E28D7,#291571);
    color: white;
}

.position-relative .dropdown-icon {
    position: absolute;
    top: 30%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #291571; 
  }
  
@media(min-width:768px){
    .contactpart{
       padding: 0px 70px !important;
    }
    
}

.card-container {
    perspective: 1000px; 
    width: 300px;
    height: 200px;
    margin: 20px;
  }
  
.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}
  
.card-container:hover .card-inner {
    transform: rotateY(180deg); 
}
  
.card-front,.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; 
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
.card-front {
    background-color: #ffffff;
}
  
.card-back {
    background-color: #007bff;
    color: white;
    transform: rotateY(180deg); 
}

.bgform{
  background-color: #291571;
}
  @media (max-width:768px){
    .startpara{
        font-size: 30px;
    }
    
    .endpara{
    font-size: 34px;
    } 
  }
  .error-message {
    color: #dc3545; 
    font-size: 14px; 
    margin-top: 5px; 
    position: relative; 
    left: 0; 
    top: 100%; 
}

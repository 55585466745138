.performancebg{
    background-image: url("../../Asset/dmsearch.png");
    background-size: cover;
}
.perform{
    font-size: 32px;
    line-height: 3rem;
}

@media (max-width:768px){
    .performancebg{
        background: linear-gradient(to right,#291571,#09091B);;
        background-repeat: no-repeat;
    }
    .perform{
        font-size: 16px;
        line-height: 2rem;
       text-align: center;
    }   
}